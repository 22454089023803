import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTE_DASHBOARD } from 'src/constants/routes'
import { Button } from '@bp-digital/component-button'
import { useRootStore } from 'src/contexts/StoreContext'
import { Icon } from '@bp-digital/component-icon'
import { Modal } from '@bp-digital/component-modal'
import { TextField } from '@bp-digital/component-text-field'
import { AdminDecisionPanelWrapper, ImageWrapper, MsgWrapper } from './AdminDecisionPanel.style'

import decisionTrees from './DecisionTrees.json'
import statementData from './Statements.json'

import AdminOlsAccount from 'src/assets/admin/admin-ols-account.jpg'
import AdminOlsUser from 'src/assets/admin/admin-ols-user.jpg'
import SetPasswordEmail from 'src/assets/admin/set-pwd-email.jpg'

const requiredImages = [
  { code: 'AdminOlsAccount', src: AdminOlsAccount },
  { code: 'AdminOlsUser', src: AdminOlsUser },
  { code: 'SetPasswordEmail', src: SetPasswordEmail }
]

const getStatements = (
  history,
  userData,
  userStore,
  setDisplayResendInviteModal,
  setDisplayEmailUpdateModal,
  setUpdateModalEmailAddress
) => {
  return statementData.map(statement => {
    const result = JSON.parse(JSON.stringify(statement))
    if (result.buttons) {
      result.buttons = result.buttons.map((btn, i) => {
        if (btn == 'IMPERSONATE') {
          return (
            <li key={`btn-${i}`}>
              <Button
                appearance='primary'
                iconName='Security'
                disabled={!userData.userUniqueId}
                onClick={() => {
                  if (!userData.userUniqueId) return
                  userStore.setImpersonatedUser(userData.userUniqueId)
                  userStore.configureUserStore()
                  history.push(ROUTE_DASHBOARD)
                }}
              >
                Impersonate
              </Button>
            </li>
          )
        }
        if (btn == 'RESEND_INVITE') {
          return (
            <li key={`btn-${i}`}>
              <Button
                appearance='primary'
                iconName='Security'
                disabled={!userData.key}
                onClick={() => {
                  if (!userData.key) return
                  setDisplayResendInviteModal(userData.key)
                }}
              >
                Resend Invite
              </Button>
            </li>
          )
        }
        if (btn == 'UPDATE_EMAIL') {
          return (
            <li key={`btn-${i}`}>
              <Button
                appearance='primary'
                iconName='Email'
                disabled={!userData.key}
                onClick={() => {
                  if (!userData.key) return
                  setUpdateModalEmailAddress('')
                  setDisplayEmailUpdateModal(userData.key)
                }}
              >
                Update Invite Email
              </Button>
            </li>
          )
        }
        return <div key={`btn-${i}`}>[Unknown Button Code]</div>
      })
    }
    return result
  })
}

const iterator = (tree, decisions) => {
  const iteration = () => {
    if (!treeIteration.suggestions) {
      return
    }
    treeIteration = treeIteration.suggestions.find(b => b.statementCode === decisionsIteration[0])
    decisionsIteration = decisionsIteration.slice(1)
  }

  let treeIteration = JSON.parse(JSON.stringify(tree)) || {}
  let decisionsIteration = JSON.parse(JSON.stringify(decisions)) || {}

  while (decisionsIteration.length) {
    iteration()
  }

  return treeIteration
}

const AdminDecisionPanel = props => {
  const userData = props.userData || {}
  const { userStore, accessAdminUserStore } = useRootStore()
  const history = useHistory()
  const [decisions, setDecisions] = useState([])
  const [imageModalIsOpen, setImageModalIsOpen] = useState(null)
  const [statusMessage, setStatusMessage] = useState('')
  const [displayResendInviteModal, setDisplayResendInviteModal] = useState(null)
  const [displayEmailUpdateModal, setDisplayEmailUpdateModal] = useState(null)
  const [updateModalEmailAddress, setUpdateModalEmailAddress] = useState('')
  const [statements, setStatements] = useState(
    getStatements(
      history,
      userData,
      userStore,
      setDisplayResendInviteModal,
      setDisplayEmailUpdateModal,
      setUpdateModalEmailAddress
    )
  )
  const initialStatementCode = props.initialStatementCode || ''
  let decisionTree = { statementCode: initialStatementCode }
  const branchIndex = decisionTrees.findIndex(branch => branch.statementCode === initialStatementCode)
  if (branchIndex > -1) {
    decisionTree = decisionTrees[branchIndex]
  }

  const getStatement = statementCode => statements.find(statement => statement.statementCode === statementCode)
  const iterationDisplay = iterator(decisionTree, decisions)

  useEffect(() => {
    if (!statements.length) {
      setStatements(
        getStatements(
          history,
          userData,
          userStore,
          setDisplayResendInviteModal,
          setDisplayEmailUpdateModal,
          setUpdateModalEmailAddress
        )
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, userData, userStore, accessAdminUserStore, setStatusMessage])

  const copytoClipboard = url => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert('Link copied to clipboard! Please paste it into an incognito window.')
      })
      .catch(err => {
        console.error('Failed to copy the link', err)
        alert('Failed to copy the link. Please try manually copying it')
      })
  }

  return (
    <AdminDecisionPanelWrapper>
      <div className='adp-label'>(Statement as per current Rio data)</div>
      <h2>{getStatement(initialStatementCode)?.statement || ''}</h2>
      {decisionTree.suggestions && decisionTree.suggestions.length > 0 && (
        <div className='adp-decisions'>
          <div className='adp-label'>(Decision logs - click to retract)</div>
          <div>
            <Icon name='RightLarge' size='md' />
            {decisions.length === 0 && <div className='adp-no-entry'>(no decision yet)</div>}
            {decisions.map((decision, index) => (
              <div key={`DK${index}`}>
                <button
                  className='adp-button'
                  onClick={() => {
                    setDecisions(decisions.slice(0, index))
                  }}
                >
                  {getStatement(decision)?.statement || ''}
                </button>
                <Icon name='RightLarge' size='md' />
              </div>
            ))}
          </div>
        </div>
      )}

      <>{statusMessage}</>

      <div className='adp-decision-tile'>
        {getStatement(iterationDisplay.statementCode)?.description && (
          <>
            <div className='adp-label'>(Description)</div>
            <div className='adp-description'>{getStatement(iterationDisplay.statementCode).description}</div>
          </>
        )}

        {getStatement(iterationDisplay.statementCode)?.links &&
          getStatement(iterationDisplay.statementCode)?.links.length > 0 && (
            <>
              <div className='adp-label'>(External Links)</div>
              <div className='incognito-mode'>
                (Click on the below link to copy and open it in Incognito mode OR Right click on the below link and
                select option Open Link in incognito window to open in incognito mode)
              </div>
              <ul className='adp-links'>
                {getStatement(iterationDisplay.statementCode).links.map((link, i) => (
                  <li key={`LK${i}`}>
                    <a
                      href={link.href}
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={e => {
                        e.preventDefault()
                        copytoClipboard(link.href)
                      }}
                    >
                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}

        {getStatement(iterationDisplay.statementCode)?.images &&
          getStatement(iterationDisplay.statementCode)?.images.length > 0 && (
            <>
              <div className='adp-label'>(Screenshots)</div>
              <ul className='adp-images'>
                {getStatement(iterationDisplay.statementCode).images.map((image, i) => {
                  const imageData = requiredImages.find(img => img.code === image.code)
                  return (
                    <li key={`IM${i}`}>
                      <button
                        className='adp-button'
                        onClick={() => {
                          setImageModalIsOpen(image)
                        }}
                      >
                        <img alt={image.label} src={imageData.src}></img>
                        <div>{image.label}</div>
                      </button>
                    </li>
                  )
                })}
              </ul>
            </>
          )}

        {getStatement(iterationDisplay.statementCode)?.buttons &&
          getStatement(iterationDisplay.statementCode)?.buttons.length > 0 && (
            <>
              <div className='adp-label'>(Actions)</div>
              <ul className='adp-links'>{getStatement(iterationDisplay.statementCode).buttons}</ul>
            </>
          )}

        {getStatement(iterationDisplay.statementCode)?.query && (
          <>
            <div className='adp-label'>(Query)</div>
            <div className='adp-query'>{getStatement(iterationDisplay.statementCode).query.text}</div>
          </>
        )}

        {iterationDisplay.suggestions && iterationDisplay.suggestions.length > 0 && (
          <>
            <div className='adp-label'>(Options)</div>
            <ul className='adp-suggestions'>
              {iterationDisplay.suggestions.map((suggestion, i) => (
                <li key={`SK${i}`}>
                  <Icon name='RightLarge' size='md' />
                  <button
                    className='adp-button'
                    onClick={() => {
                      setDecisions(decisions.concat([suggestion.statementCode]))
                    }}
                  >
                    {getStatement(suggestion.statementCode)?.statement || ''}
                  </button>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>

      {imageModalIsOpen !== null && (
        <Modal title={imageModalIsOpen.label} onDismiss={() => setImageModalIsOpen(null)} visible size='lg'>
          {imageModalIsOpen.code !== '' && (
            <ImageWrapper>
              <img
                alt={imageModalIsOpen.label}
                src={requiredImages.find(img => img.code === imageModalIsOpen.code).src}
              ></img>
            </ImageWrapper>
          )}
        </Modal>
      )}

      {displayResendInviteModal !== null && (
        <Modal
          title='Resend Invitation'
          primaryAction={{
            text: 'Send',
            onClick: async () => {
              try {
                setDisplayResendInviteModal(null)
                setStatusMessage(<MsgWrapper>Processing...</MsgWrapper>)
                await accessAdminUserStore.resendInvite(displayResendInviteModal, userStore.selectedAuthorityId)
                accessAdminUserStore.reset()
                setStatusMessage(<MsgWrapper className='success'>Resent invite sent successfully</MsgWrapper>)
              } catch (error) {
                setStatusMessage(<MsgWrapper className='error'>Resent invite failed</MsgWrapper>)
              }
            },
            iconName: '',
            disabled: false
          }}
          secondaryAction={{
            text: 'Cancel',
            onClick: () => setDisplayResendInviteModal(null),
            appearance: 'tertiary'
          }}
          onDismiss={() => setDisplayResendInviteModal(null)}
          visible
          size='lg'
        >
          Note that this will invalidate any previous invitation for this user
        </Modal>
      )}

      {displayEmailUpdateModal !== null && (
        <Modal
          title='Update Email Address'
          primaryAction={{
            text: 'Update',
            onClick: async () => {
              try {
                setDisplayEmailUpdateModal(null)
                setStatusMessage(<MsgWrapper>Processing...</MsgWrapper>)
                await accessAdminUserStore.updateInviteEmail(displayEmailUpdateModal, updateModalEmailAddress)
                accessAdminUserStore.reset()
                setStatusMessage(<MsgWrapper className='success'>Email address updated</MsgWrapper>)
              } catch (error) {
                setStatusMessage(
                  <MsgWrapper className='error'>email address can not be same as current address</MsgWrapper>
                )
              }
            },
            iconName: '',
            disabled: false
          }}
          secondaryAction={{
            text: 'Cancel',
            onClick: () => setDisplayEmailUpdateModal(null),
            appearance: 'tertiary'
          }}
          onDismiss={() => setDisplayEmailUpdateModal(null)}
          visible
          size='lg'
        >
          <TextField label='New Email Address ' value={updateModalEmailAddress} onChange={setUpdateModalEmailAddress} />
          <div className='adp-label'>(email address can not be same as current address)</div>
        </Modal>
      )}
    </AdminDecisionPanelWrapper>
  )
}

export default AdminDecisionPanel
